




.BalanceMainFigureCardTitle{
    color: black!important;
}
.card-title{
    color: black!important;
}