
.ProductWindow-Image-Adding{
    position: relative;
    display: inline-block;
    /*width: auto;*/
}
.ProductWindow-Image-Adding-OnHover{
    position: absolute;
    z-index: -100;
    top: 0;
    height: 100%;
    width: 100%;
}
.ProductWindow-Image-Adding:hover{
    cursor: pointer;

}
.ProductWindow-Image-Adding:hover .ProductWindow-Image-Adding-OnHover{
    position: absolute;
    background-color: rgba(71, 71, 71, 0.79);
    border-radius: 1vh;
    line-height: 20vh;
    z-index: 1000;
    text-align: center;
    color: white;
    /*font-family: 'Lobster', cursive;*/
}


.Product-Card-Title{
    margin-bottom: 0vh;
    padding: .25rem .75rem;
    text-align: center;
    font-weight: 800;
}
.Product-Card-Description{
    height: 18.35vh;
    overflow: auto;
}

