/*.card-bg-1:nth-child(4n - 3) {*/
/*    background-color: #FFFFFF;*/
/*    background-image: linear-gradient(226deg, #FFFFFF 0%, #6284FF 25%, #FF0000 100%);*/
/*    color: white !important;*/
/*}*/
/*.card-bg-2:nth-child(4n - 3) {*/
/*    background-color: #52ACFF;*/
/*    background-image: linear-gradient(63deg, #52ACFF 29%, #d6bc00 69%, #ffffff 100%);*/
/*    color: white !important;*/
/*}*/
/*.card-bg-3:nth-child(4n - 3) {*/
/*    background-color: #21D4FD;*/
/*    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);*/
/*    color: white !important;*/
/*}*/
/*.card-bg-4:nth-child(4n - 3) {*/
/*    background-color: #08AEEA;*/
/*    background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);*/

/*    color: white !important;*/
/*}*/
/*.card-bg-5:nth-child(4n - 3) {*/
/*    background-color: #21D4FD;*/
/*    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);*/
/*    color: white !important;*/
/*}*/
.card-bg-1:nth-child(4n - 3) {
    background-color: #ffffff;
}
.card-bg-2:nth-child(4n - 3) {
    background-color: #ffffff;
}
.card-bg-3:nth-child(4n - 3) {
    background-color: #ffffff;
}
.card-bg-4:nth-child(4n - 3) {
    background-color: #ffffff;
}
.card-bg-5:nth-child(4n - 3) {
    background-color: #ffffff;
    /*background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);*/
    /*color: white !important;*/
}

.card-title {
    color: rgba(18, 21, 78, 0.7) !important;
}
