



/*.Basket-Container{*/
/*    position: fixed;*/
/*    z-index: 1000;*/
/*    height: 50%;*/
/*    width: calc(25vh + 25vw);*/
/*    min-width: 330px;*/
/*    max-width: calc(100% - 8rem);*/
/*    top: 3vh;*/
/*    !*right: 0;*!*/
/*    !*left: 100%;*!*/
/*    background-color: white;*/
/*    box-shadow: 0vh 5px 15px rgba(0, 0, 0, 0.5);*/
/*    border-radius: 2vh;*/

/*    transition: left 0.25s, right 0.25s, transform 0.25s;*/
/*}*/
/*.Basket-Logo{*/
/*    position: absolute;*/
/*    !*width: calc(3vw + 3vh + 5vh + 5vw);*!*/
/*    !*height: calc(3vw + 3vh);*!*/
/*    !*left: calc(-3vh - 3vw);*!*/
/*    width: 8rem;*/
/*    height: 4rem;*/
/*    left: -4rem;*/


/*    background-color: white;*/
/*    box-shadow: 0vh 5px 15px rgba(0, 0, 0, 0.5);*/

/*    top: 0;*/
/*    border-radius: 2vh;*/
/*    cursor: pointer;*/
/*}*/
/*.Basket-Logo-Image{*/
/*    position: absolute;*/
/*    height: 70%;*/
/*    top: 50%;*/
/*    left: 25%;*/
/*    margin-right: -50%;*/
/*    transform: translate(-50%, -50%);*/
/*    !*width: 100%;*!*/
/*}*/
/*.Basket-Logo-Badge{*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 0%;*/
/*    margin-right: -50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
/*.Basket-Content-Container{*/
/*    position: relative;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    min-width: 100%;*/
/*    background-color: white;*/
/*    box-shadow: 0vh 5px 15px rgba(0, 0, 0, 0.5);*/
/*    border-radius: 2vh;*/
/*    max-height: 100%;*/
/*    overflow: auto;*/
/*}*/
/*.Basket-Content-Container-Items{*/

/*}*/
/*.btn {*/
/*    margin-right: 1vh;*/
/*    box-shadow: 0vh 5px 15px rgba(0, 0, 0, 0.5);*/
/*}*/






/*.Basket-SendOrder-ModalContainer{*/
/*    z-index: 2000;*/
/*    position: absolute;*/
/*}*/
