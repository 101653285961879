





.custom-control-label{
    cursor: pointer;
}



.ProductWindow-Image-Adding{
    position: relative;
    display: inline-block;
    /*width: auto;*/
}
.ProductWindow-Image-Adding-OnHover{
    position: absolute;
    z-index: -100;
    top: 0;
    height: 100%;
    width: 100%;
}
.ProductWindow-Image-Adding:hover{
    cursor: pointer;

}
.ProductWindow-Image-Adding:hover .ProductWindow-Image-Adding-OnHover{
    position: absolute;
    background-color: rgba(71, 71, 71, 0.79);
    border-radius: 1vh;
    line-height: 20vh;
    z-index: 1000;
    text-align: center;
    color: white;
    /*font-family: 'Lobster', cursive;*/
}


.Product-Card-Title{
    margin-bottom: 0vh;
    padding: .25rem .75rem;
    text-align: center;
    font-weight: 800;
}

.Product-Card-Description{
    height: 18.35vh;
    overflow: auto;
}


.tooltip-content {
    display: inline-block;
    position: relative;
}

.tooltip-content .card {
    display: none;
    position: absolute;
    padding: 10px;
    z-index: 1000;
    width: 250%;
    left: -75%;
    top: 100%;
}

.tooltip-content button:hover+.card {
    display: flex;
}

.progress-bar {
    height: 20px;
}




.ProductRibbonContainer{
    position: absolute;
}
.productRibbon{
    width: calc(4vh + 4vw);
    height: calc(.9vh + .9vw);
    font-size: calc(.5vw + .5vh);
    padding: .2vh;
    margin: .2vh;
    color: white;
    border-radius: calc(.2vh + .2vw);
}
.newProductRibbon{
    background-color: #3ac47d;
}
.topProductRibbon{
    background-color: #d92550;
}
.priceProductRibbon{
    background-color: #f7b924;
}
