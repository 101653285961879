.tg-button {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;
    background-color: #52ACFF;
    background-image: linear-gradient(225deg, #52ACFF 25%, #1389da 100%);
    border: none;
    border-radius: 5px;
    padding: 7px;
    box-shadow: 1px 1px 1px 1px rgba(132, 132, 132, 0.31);
    cursor: pointer;
}

.tg-button span {
    color: white;
    font-weight: bold;
}

.tg-button img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
