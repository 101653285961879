@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@900&display=swap');




.Landing-Container-Fragment1{
    height: calc(100vh - 60px);
}
.Landing-Container-Fragment1-Col2{
    text-align: center;
    /*height: 100%;*/
    width: 100%;
}
.Landing-Container-Fragment1-Man-Image-Container{
    height: 100%;
    width: 100%;
    /*border: 1px solid #1a1a1a ;*/
}
.Landing-Container-Fragment1-Man-Image{
    position: absolute;
    left: 50%;
    /*top: 50%;*/
    transform: translate(-50%, -0%);
    max-height: 100%;
    max-width: 90%;
    bottom: 0;
}
.Landing-Container-Fragment1-Man-RoundFigure1{
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    width: 50vh;
    border-radius: 50%;
    /*background-color: #ebebeb;*/
}
.Landing-Container-Fragment1-Man-Dot{
    position: relative;
    height: .6vh;
    width: .6vh;
    margin: 2vh;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    z-index: 99;
}

.Landing-Container-Fragment1-Man-RoundFigure2{
    position: absolute;
    height: 95%;
    width: 250%;
    left: 60%;
    /*top: 50%;*/
    transform: translate(-50%, -0%);
    max-height: 100%;
    max-width: 90%;
    bottom: 0;
    /*background-color: #dbdbdb;*/
    background: linear-gradient(#e66465, #9198e5);
    border-top-left-radius: 50%;
    animation: wobble 15s ease-in-out alternate infinite;
}
@keyframes wobble {
    0% {
        left: 55%;
        border-top-left-radius: 50%;
    }
    50% {
        left: 60%;
        border-top-left-radius: 65%;
    }
    100% {
        left: 65%;
        border-top-left-radius: 30%;
    }
}



.Landing-Title-Container{
    width: 100%;
    height: 100%;
    text-align: center;
    color: black;
    z-index: 999;
}
.Landing-Title-Container-Title1{
    font-size: calc(2vh + 2vw);
    font-weight: bold;
    z-index: 999;
}
.Landing-Title-Container-Title2{
    font-family: 'Raleway', sans-serif;
    font-size: calc(4.2vh + 4.2vw);
    line-height: calc(3.3vh + 3.3vw);
    font-weight: bold;
    z-index: 999;
}
.Landing-Title-Container-Title3{
    font-size: calc(1.6vh + 1.6vw);
    z-index: 999;
    /*color: #6f6f6f;*/
}


.Landing-Container-Fragment2{
    font-family: 'Exo 2', sans-serif;
    color: black;
    text-align: center;
}







.Landing-Container-FragmentImages{
    /*height: 55vh;*/
    max-height: 100%;
    width: 100%;
}

.Landing-Container-FragmentImages-Image{
    /*height: 100vh;*/
    width: 100%;
    max-height: 100%;
}
.Landing-Container-FragmentImages-Col{
    max-height: 100%;
    padding: 20px;
}
.Landing-Container-FragmentImages-Card{
    max-height: 100%;
}



.CardProductsAnimation{
    cursor: pointer;
    transition: all 1s;
}
.CardProductsAnimation:hover{
    transform: scale(1.1);
}










.LineAnimationContainer{
    position: absolute;
    height: 100%;
    width: 100%;
}
.LineAnimation1{
    /*background-color: #bb0052;*/
    position: absolute;
    top: 10%;
    border-top: 1vh solid #bb0052;
    width: 150%;
    height: 1vh;
    transform: rotate(5deg);
    animation: lineAnimation 5s ease-in-out alternate infinite;
}
.LineAnimation2{
    /*background-color: #bb0052;*/
    position: absolute;
    top: 25%;
    border-top: 1vh solid #ffc500;
    width: 150%;
    height: 1vh;
    transform: rotate(5deg);
    animation: lineAnimation2 10s ease-in-out alternate infinite;
}
.LineAnimation3{
    /*background-color: #bb0052;*/
    position: absolute;
    top: 30%;
    border-top: 1vh solid #4800bb;
    width: 150%;
    height: 1vh;
    transform: rotate(5deg);
    animation: lineAnimation3 7s ease-in-out alternate infinite;
}
.LineAnimation4{
    /*background-color: #bb0052;*/
    position: absolute;
    top: 55%;
    border-top: 1vh solid #008dbb;
    width: 150%;
    height: 1vh;
    transform: rotate(5deg);
    animation: lineAnimation4 12s ease-in-out alternate infinite;
}


@keyframes lineAnimation {
    from {
        transform: rotate(5deg);
        width: 0%;
    }
    to{
        transform: rotate(-5deg);
        width: 100%;
    }
}
@keyframes lineAnimation2 {
    from {
        transform: rotate(15deg);
        width: 100%;
    }
    to{
        transform: rotate(-15deg);
        width: 0%;
    }
}
@keyframes lineAnimation3 {
    from {
        transform: rotate(20deg);
        width: 0%;
    }
    to{
        transform: rotate(-20deg);
        width: 100%;
    }
}
@keyframes lineAnimation4 {
    from {
        transform: rotate(10deg);
        width: 100%;
    }
    to{
        transform: rotate(-10deg);
        width: 0%;
    }
}














.LandingMorphingElement{
    position: absolute;
    top: 0%;
    right: 10%;
    height: calc(10vh + 10vw);
    width: calc(10vh + 10vw);
    background: linear-gradient(#e66465, #9198e5);
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    animation: morphing 10s infinite;
    overflow: hidden;
    z-index: 0;
}







@keyframes morphing {
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
    }
    25% {
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    }
    50% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
        box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
    }
    75% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
    }
}









.Landing-First-Container{
    position: relative;
    /*top: 10vh;*/
    height: 100vh;
    width: 100%;

    overflow: hidden;
}
.Landing-Else-Container{
    position: relative;
    /*top: 100vh;*/
    height: auto;
    width: 100%;
}
.Landing-Else-Container-100{
    position: relative;
    /*top: 200vh;*/
    height: 100vh;
    width: 100%;
}






.Landing-Fon{
    position: absolute;
    height: 440%;
    width: 100%;
}






.Landing-MainPhoto-Container{
    position: absolute;
    bottom: 0;
    left: 0;
}
.Landing-MainPhoto-Photo{
    position: relative;

    width: calc(30vw + 15vh);
}


.Landing-Navigator-Container{
    box-shadow: 0 0 1vh rgb(135, 135, 135);
    z-index: 1000;

    /*text-shadow: 3px 3px 0 white;*/
    /*font-family: 'Ubuntu Condensed', sans-serif;*/
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Navigator-Logo{
    position: relative;
    text-align: center;
    font-size: calc(1vw + 4vh);
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Navigator-Container-Items{
    font-size: calc(0.5vw + 1.5vh);
    /*font-family: 'Ubuntu Condensed', sans-serif;*/
}






.Landing-Title-Container-Mob{
    position: absolute;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    text-align: center;

}
.Landing-Title-First{
    position: relative;
    font-size: calc(5vw + 4vh);
    text-shadow: 3px 3px 0 white;
    /*font-family: 'Lobster', cursive;*/
    line-height: calc(5vw + 3vh);
}
.Landing-Title-Second{
    position: relative;
    font-size: calc(4vw + 2vh);
    line-height: calc(5vw + 3vh);
    text-shadow: 3px 3px 0 white;
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Title-Separator{
    position: relative;
    width: 100%;
    height: 0.5vh;
    border-radius: 1vw;
    background-color: #000000;
    box-shadow: 3px 3px 0 white;
}
.Landing-Title-Third{
    position: relative;
    font-size: calc(1.5vw + 3vh);
    /*font-family: 'Ubuntu Condensed', sans-serif;*/
}
.Landing-Title-Four{
    position: relative;
    font-size: calc(0.5vw + 1.5vh);
    /*font-family: 'Ubuntu Condensed', sans-serif;*/
}
.Landing-Title-Button{
    position: relative;
    /*font-family: 'Lobster', cursive;*/
}


.Landing-Small-Label{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    font-size: calc(1vw + 4vh);
    /*font-family: 'Lobster', cursive;*/
}











/*Second PAge*/
.Landing-Second-Container-Title-First{
    position: absolute;
    top: 5vh;
    margin-left: 5%;
    text-shadow: 3px 3px 0 white;
    font-size: calc(3vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Second-Container-Title-Second{
    position: absolute;
    top: 18vh;
    right: 5%;
    text-align: center;
    text-shadow: 3px 3px 0 white;
    font-size: calc(2vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
}



.Landing-Second-Container-Strelka{

    position: absolute;
    top: 60%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
.Landing-Second-Container-Strelka-Img{
    height: calc(5vw + 10vh);
    width: calc(10vw + 10vh);
}








.Landing-Second-Container-Punkt-First{
    position: absolute;
    text-align: center;
    width: calc(15vw + 15vh);
    top: 40vh;
    left: 15vw;
}
.Landing-Second-Container-Punkt-Second{
    position: absolute;
    text-align: center;
    width: calc(15vw + 15vh);
    top: 70vh;
    right: 15vw;
}
.Landing-Second-Container-Punkt-Title{
    position: relative;
    text-shadow: 3px 3px 0 white;
    font-size: calc(1.5vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
    white-space: pre;
}
.Landing-Second-Container-Punkt-Text{
    position: relative;
    font-size: calc(1vw + 1vh);
}











/*Third*/
.Landing-Third-Container-Title-Container{
    position: relative;
    height: 40vh;
}
.Landing-Third-Container-Title-First{
    position: absolute;
    /*top: 5vh;*/
    margin-left: 5%;
    text-shadow: 3px 3px 0 white;
    font-size: calc(3vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Third-Container-Title-Second{
    position: absolute;
    top: 15vh;
    right: 5%;
    text-align: center;
    text-shadow: 3px 3px 0 white;
    font-size: calc(2vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Third-Container-Cards{
    position: relative;
    /*top: 30vh;*/
    width: 100%;
    height: auto;
    text-align: center;

}
.Landing-Third-Container-Card{
    position: relative;
    display: inline-block;
    box-shadow: 0 0 1vh rgb(135, 135, 135);
    margin: 1vh;
}
.Landing-Third-Container-Card-Title{
    text-shadow: 3px 3px 0 white;
    font-size: calc(1.5vw + 3vh);
    /*font-family: 'Lobster', cursive;*/
}
.Landing-Third-Container-Card-Text{
    /*font-family: 'Ubuntu Condensed', sans-serif;*/
}

.Landing-Third-Container-All-Products{
    position: relative;
    text-shadow: 3px 3px 0 white;
    font-family: 'Lobster', cursive;

    font-size: calc(1.5vw + 3vh);
    width: 100%;
    text-align: center;
}
.Landing-Third-Container-All-Products-Title{

}










/*Four    */
.Landing-Otstup{
    position: relative;
    height: 25vh;
    width: 100%;
}
.Landing-Four-Container-Title-Container{
    position: relative;
    height: 25vh;
}
.Landing-Four-Container-Title-Second{
    position: absolute;
    top: 12vh;
    right: 5%;
    text-align: center;
    text-shadow: 3px 3px 0 white;
    font-size: calc(1.5vw + 2.5vh);
    /*font-family: 'Lobster', cursive;*/
}


.Landing-Four-Container-Form{
    position: relative;
    height: 40vh;
    width: 40%;
    margin-left: 10vh;

    line-height: 3vh;

    /*text-align: center;*/
}
.Landing-Form-Label{
    text-shadow: 3px 3px 0 white;
    font-size: calc(1vw + 2vh);
    font-family: 'Lobster', cursive;
}
.Landing-Form-Control{
    background-color: rgba(0, 0, 0, 0);
    height: 7vh;
    border: 1px solid black;
}
.Landing-Form-Button{
    position: relative;
    height: 7vh;
    /*font-family: 'Lobster', cursive;*/
    width: 100%;
}

.step-mark {
    color: #545cd8;
    text-align: center;
}

.step-mark h1 {
    font-size: 140pt;
    line-height: 1;
    margin: 0;
    font-weight: bold;
}

.step-mark p {
    font-size: 60pt;
    line-height: 1;
    margin: 0;
    /*font-family: Lobster;*/
}

.step-list {
    /*font-size: 1.8em;*/
}

.step-list h2 {
    text-align: center;
}

.step-list div {
    margin: auto;
    width: max-content;
    justify-content: left;
    display: grid;
    grid-template-columns: 45px max-content;
    grid-gap: 5px;

}





.LandingCardProductsFooter {
    text-align: center!important;
    display: block!important;
}